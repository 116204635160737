import React from 'react';
import { Link } from 'react-router-dom';

export default class Footer extends React.Component {
    render() {
        return (
            <footer className='global-footer'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-4 col-md-7'>
                            <div className='content-styled'>
                                <h2>
                                    Ready to simplify all aspects of bond
                                    issuance?
                                </h2>
                                <p>
                                    <a href='https://help.adajeinc.com/meetings/dan-silva/personalized-adaje-demo'>
                                        Schedule a demo
                                    </a>{' '}
                                    to learn more.
                                </p>
                            </div>
                        </div>
                        <div className='col-lg-8 col-md-5'>
                            <nav className='footer'>
                                <ul className="menu">
                                    <li className="menu-item" key={1}>
                                        <Link to="/borrower">
                                            For Borrowers
                                        </Link>
                                    </li>
                                    <li className="menu-item" key={2}>
                                        <Link to="/advisorbanker">
                                            For Advisors & Bankers
                                        </Link>
                                    </li>
                                    <li className="menu-item" key={3}>
                                        <Link to="/company">Company</Link>
                                    </li>
                                    <li className="menu-item" key={4}>
                                        {this.props.signInUrl()}
                                    </li>
                                    <li className="menu-item h5" key={5}>
                                        <a href="https://help.adajeinc.com/meetings/dan-silva/personalized-adaje-demo">
                                            Schedule Demo
                                        </a>
                                    </li>
                                </ul>
                                <ul className="menu pt-5">
                                    <li className="menu-item" key={1}>
                                        <Link to="/privacy">Privacy</Link>
                                    </li>
                                    <li className="menu-item" key={2}>
                                        <Link to="/terms">Terms</Link>
                                    </li>
                                    <li className="menu-item" key={3}>
                                        <Link to="/disclosure">Disclosure</Link>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                    <div className="row bottom-row">
                        <div className="col-md-8">
                            <p className="copyright">
                                &copy; 2023 Adaje Inc. All Rights Reserved.
                                {/*<span className="privacy"><a>Privacy Policy</a></span>*/}
                            </p>
                        </div>
                        <div className='col-md-4'>
                            <Link to='/' className='logo-link'>
                                <svg
                                    width='179'
                                    height='45'
                                    viewBox='0 0 179 45'
                                    fill='none'
                                    xmlns='http://www.w3.org/2000/svg'>
                                    <g clipPath='url(#clip0)'>
                                        <path
                                            className='st1'
                                            d='M78.192 28.7279H64.944L62.28 36.6479H56.16L67.68 5.54395H75.672L87.264 36.6479H80.928L78.192 28.7279ZM66.456 24.2639H76.608L75.312 20.4479C74.232 17.2799 73.152 13.7519 72.216 10.6559H70.92C69.984 13.7519 68.832 17.2799 67.752 20.4479L66.456 24.2639Z'
                                            fill='white'
                                        />
                                        <path
                                            className='st1'
                                            d='M106.704 30.2398H106.344C105.12 34.4158 102.168 37.3678 97.8479 37.3678C92.2319 37.3678 88.9919 32.8318 88.9919 24.6238C88.9919 16.2718 92.3039 11.6638 97.9919 11.7358C102.384 11.6638 105.12 14.4718 106.344 18.7198H106.704V3.38379H112.248V36.6478H106.704V30.2398ZM106.704 25.5598V23.6878C106.704 19.5118 104.256 16.7038 100.512 16.7038C96.4799 16.7038 94.4639 19.2238 94.4639 24.5518C94.4639 29.8078 96.3359 32.3278 100.512 32.3278C104.256 32.3278 106.704 29.6638 106.704 25.5598Z'
                                            fill='white'
                                        />
                                        <path
                                            className='st1'
                                            d='M133.56 30.8158H132.984C131.76 34.7758 128.952 37.1518 124.632 37.1518C120.312 37.1518 117.216 35.0638 117.216 30.3118C117.216 25.9918 119.88 22.8238 126 22.8238H133.272V21.1678C133.272 17.9998 131.976 16.1998 128.52 16.1998C125.64 16.1998 123.984 17.6398 123.408 20.5198L118.152 19.2958C119.088 14.6878 122.544 11.7358 128.808 11.7358C135.864 11.7358 138.888 15.0478 138.888 21.0958V36.6478H133.632V30.8158H133.56ZM133.344 27.4318V26.4238H127.008C124.2 26.4238 122.616 27.4318 122.616 29.4478C122.616 31.1758 123.696 32.6878 126.864 32.6878C130.752 32.8318 133.344 30.5278 133.344 27.4318Z'
                                            fill='white'
                                        />
                                        <path
                                            className='st1'
                                            d='M142.344 45C141.48 45 140.688 44.928 140.04 44.712L140.328 39.888C140.976 39.96 141.552 40.104 142.2 40.104C144.144 40.104 144.792 38.736 144.792 36.648V12.312H150.336V36.072C150.336 41.544 148.032 45 142.344 45Z'
                                            fill='white'
                                        />
                                        <path
                                            className='st1'
                                            d='M155.232 24.7681C155.232 16.5601 159.912 11.6641 167.544 11.6641C174.888 11.6641 178.488 16.1281 178.488 22.8241C178.488 23.7601 178.488 24.8401 178.416 26.0641H160.848C161.28 30.4561 163.512 32.6881 167.616 32.6881C171.504 32.6881 172.872 30.8161 173.52 28.5121L178.272 29.8801C177.12 34.2721 174.096 37.2961 167.544 37.2961C160.056 37.2241 155.232 32.9041 155.232 24.7681ZM160.848 22.3201H173.376C173.16 18.1441 171.288 15.9121 167.4 15.9121C163.584 15.9841 161.352 18.0001 160.848 22.3201Z'
                                            fill='white'
                                        />
                                        <path
                                            className='st0'
                                            d='M150.336 3.38379H144.792V8.92779H150.336V3.38379Z'
                                            fill='#F15F34'
                                        />
                                        <path
                                            className='st0'
                                            d='M36.576 23.6162H23.616V36.5762H36.576V23.6162Z'
                                            fill='#F15F34'
                                        />
                                        <path
                                            className='st1'
                                            d='M25.992 0L0 25.992V36.648H10.656L36.648 10.656V0H25.992ZM31.968 8.712C31.968 8.712 30.528 12.168 21.384 21.384C12.168 30.528 8.712 31.968 8.712 31.968H4.68V27.936C4.68 27.936 6.12 24.48 15.264 15.264C24.408 6.12 27.936 4.68 27.936 4.68H31.968V8.712Z'
                                            fill='white'
                                        />
                                    </g>
                                    <defs>
                                        <clipPath id='clip0'>
                                            <rect
                                                className='st1'
                                                width='178.488'
                                                height='45'
                                                fill='white'
                                            />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </Link>
                        </div>
                    </div>
                </div>
                <a className='to-top' href='#backtotop'>
                    Back to Top
                </a>
            </footer>
        );
    }
}
