import React from 'react';
import { UTIL } from './assets/js/main';
import { Helmet } from 'react-helmet';

class Disclosure extends React.Component {
    componentDidMount() {
        UTIL.loadEvents();
        window.scrollTo(0, 0);
    }

    render() {
        const packages = [
            {
                department: 'kessler',
                relatedTo: 'stuff',
                name: '@emotion/react',
                licensePeriod: 'perpetual',
                material: 'material',
                licenseType: 'MIT',
                link: 'git+https://github.com/emotion-js/emotion.git#main',
                remoteVersion: '11.11.1',
                installedVersion: '11.10.6',
                definedVersion: '^11.10.6',
                author: 'Emotion Contributors'
            },
            {
                department: 'kessler',
                relatedTo: 'stuff',
                name: '@emotion/styled',
                licensePeriod: 'perpetual',
                material: 'material',
                licenseType: 'MIT',
                link: 'git+https://github.com/emotion-js/emotion.git#main',
                remoteVersion: '11.11.0',
                installedVersion: '11.10.6',
                definedVersion: '^11.10.6',
                author: 'n/a'
            },
            {
                department: 'kessler',
                relatedTo: 'stuff',
                name: '@fontsource/roboto',
                licensePeriod: 'perpetual',
                material: 'material',
                licenseType: 'MIT',
                link: 'https://github.com/fontsource/fontsource.git',
                remoteVersion: '4.5.8',
                installedVersion: '4.5.8',
                definedVersion: '^4.5.8',
                author: 'Lotus <declininglotus@gmail.com>'
            },
            {
                department: 'kessler',
                relatedTo: 'stuff',
                name: '@handsontable/react',
                licensePeriod: 'perpetual',
                material: 'material',
                licenseType: 'SEE LICENSE IN LICENSE.txt',
                link: 'git+https://github.com/handsontable/handsontable.git',
                remoteVersion: '12.4.0',
                installedVersion: '12.3.1',
                definedVersion: '^12.3.1',
                author: 'Handsoncode <hello@handsoncode.net> (https://handsoncode.net)'
            },
            {
                department: 'kessler',
                relatedTo: 'stuff',
                name: '@hookform/resolvers',
                licensePeriod: 'perpetual',
                material: 'material',
                licenseType: 'MIT',
                link: 'git+https://github.com/react-hook-form/resolvers.git',
                remoteVersion: '2.9.11',
                installedVersion: '2.9.11',
                definedVersion: '^2.9.11',
                author: 'bluebill1049 <bluebill1049@hotmail.com>'
            },
            {
                department: 'kessler',
                relatedTo: 'stuff',
                name: '@mui/icons-material',
                licensePeriod: 'perpetual',
                material: 'material',
                licenseType: 'MIT',
                link: 'git+https://github.com/mui/material-ui.git',
                remoteVersion: '5.14.16',
                installedVersion: '5.11.16',
                definedVersion: '^5.11.16',
                author: 'MUI Team'
            },
            {
                department: 'kessler',
                relatedTo: 'stuff',
                name: '@mui/lab',
                licensePeriod: 'perpetual',
                material: 'material',
                licenseType: 'MIT',
                link: 'git+https://github.com/mui/material-ui.git',
                remoteVersion: '5.0.0-alpha.152',
                installedVersion: '5.0.0-alpha.119',
                definedVersion: '^5.0.0-alpha.119',
                author: 'MUI Team'
            },
            {
                department: 'kessler',
                relatedTo: 'stuff',
                name: '@mui/material',
                licensePeriod: 'perpetual',
                material: 'material',
                licenseType: 'MIT',
                link: 'git+https://github.com/mui/material-ui.git',
                remoteVersion: '5.14.17',
                installedVersion: '5.12.1',
                definedVersion: '^5.12.1',
                author: 'MUI Team'
            },
            {
                department: 'kessler',
                relatedTo: 'stuff',
                name: '@mui/x-date-pickers',
                licensePeriod: 'perpetual',
                material: 'material',
                licenseType: 'MIT',
                link: 'git+https://github.com/mui/mui-x.git',
                remoteVersion: '6.18.1',
                installedVersion: '6.2.0',
                definedVersion: '^6.2.0',
                author: 'MUI Team'
            },
            {
                department: 'kessler',
                relatedTo: 'stuff',
                name: 'allotment',
                licensePeriod: 'perpetual',
                material: 'material',
                licenseType: 'MIT',
                link: 'git+https://github.com/johnwalley/allotment.git',
                remoteVersion: '1.19.3',
                installedVersion: '1.18.0',
                definedVersion: '^1.18.0',
                author: 'johnwalley <john@walley.org.uk>'
            },
            {
                department: 'kessler',
                relatedTo: 'stuff',
                name: 'aws-amplify',
                licensePeriod: 'perpetual',
                material: 'material',
                licenseType: 'Apache-2.0',
                link: 'git+https://github.com/aws-amplify/amplify-js.git',
                remoteVersion: '5.3.12',
                installedVersion: '5.3.6',
                definedVersion: '^5.0.14',
                author: 'Amazon Web Services'
            },
            {
                department: 'kessler',
                relatedTo: 'stuff',
                name: 'big.js',
                licensePeriod: 'perpetual',
                material: 'material',
                licenseType: 'MIT',
                link: 'git+https://github.com/MikeMcl/big.js.git',
                remoteVersion: '6.2.1',
                installedVersion: '6.2.1',
                definedVersion: '^6.2.1',
                author: 'Michael Mclaughlin M8ch88l@gmail.com'
            },
            {
                department: 'kessler',
                relatedTo: 'stuff',
                name: 'chart.js',
                licensePeriod: 'perpetual',
                material: 'material',
                licenseType: 'MIT',
                link: 'git+https://github.com/chartjs/Chart.js.git',
                remoteVersion: '4.4.0',
                installedVersion: '4.3.0',
                definedVersion: '^4.3.0',
                author: 'n/a'
            },
            {
                department: 'kessler',
                relatedTo: 'stuff',
                name: 'chartjs-plugin-zoom',
                licensePeriod: 'perpetual',
                material: 'material',
                licenseType: 'MIT',
                link: 'git+https://github.com/chartjs/chartjs-plugin-zoom.git',
                remoteVersion: '2.0.1',
                installedVersion: '2.0.0',
                definedVersion: '^2.0.0',
                author: 'n/a'
            },
            {
                department: 'kessler',
                relatedTo: 'stuff',
                name: 'd3',
                licensePeriod: 'perpetual',
                material: 'material',
                licenseType: 'ISC',
                link: 'git+https://github.com/d3/d3.git',
                remoteVersion: '7.8.5',
                installedVersion: '7.8.2',
                definedVersion: '^7.8.1',
                author: 'Mike Bostock https://bost.ocks.org/mike'
            },
            {
                department: 'kessler',
                relatedTo: 'stuff',
                name: 'dayjs',
                licensePeriod: 'perpetual',
                material: 'material',
                licenseType: 'MIT',
                link: 'git+https://github.com/iamkun/dayjs.git',
                remoteVersion: '1.11.10',
                installedVersion: '1.11.7',
                definedVersion: '^1.11.7',
                author: 'iamkun'
            },
            {
                department: 'kessler',
                relatedTo: 'stuff',
                name: 'handsontable',
                licensePeriod: 'perpetual',
                material: 'material',
                licenseType: 'SEE LICENSE IN LICENSE.txt',
                link: 'git+https://github.com/handsontable/handsontable.git',
                remoteVersion: '12.4.0',
                installedVersion: '12.3.1',
                definedVersion: '^12.3.1',
                author: 'Handsoncode <hello@handsontable.com>'
            },
            {
                department: 'kessler',
                relatedTo: 'stuff',
                name: 'html-to-image',
                licensePeriod: 'perpetual',
                material: 'material',
                licenseType: 'MIT',
                link: 'git+https://github.com/bubkoo/html-to-image.git',
                remoteVersion: '1.11.11',
                installedVersion: '1.11.11',
                definedVersion: '^1.11.11',
                author: 'bubkooo bubkoo.wy@gmail.com'
            },
            {
                department: 'kessler',
                relatedTo: 'stuff',
                name: 'immer',
                licensePeriod: 'perpetual',
                material: 'material',
                licenseType: 'MIT',
                link: 'git+https://github.com/immerjs/immer.git',
                remoteVersion: '9.0.21',
                installedVersion: '9.0.19',
                definedVersion: '^9.0.19',
                author: 'Michel Weststrate'
            },
            {
                department: 'kessler',
                relatedTo: 'stuff',
                name: 'lodash',
                licensePeriod: 'perpetual',
                material: 'material',
                licenseType: 'MIT',
                link: 'git+https://github.com/lodash/lodash.git',
                remoteVersion: '4.17.21',
                installedVersion: '4.17.21',
                definedVersion: '^4.17.21',
                author: 'John-David Dalton <john.david.dalton@gmail.com>'
            },
            {
                department: 'kessler',
                relatedTo: 'stuff',
                name: 'notistack',
                licensePeriod: 'perpetual',
                material: 'material',
                licenseType: 'MIT',
                link: 'git+https://github.com/iamhosseindhv/notistack.git',
                remoteVersion: '2.0.8',
                installedVersion: '2.0.8',
                definedVersion: '^2.0.8',
                author: 'Hossein Dehnokhalaji hossein.dehnavi98@yahoo.com https://iamhosseindhv.com'
            },
            {
                department: 'kessler',
                relatedTo: 'stuff',
                name: 'react',
                licensePeriod: 'perpetual',
                material: 'material',
                licenseType: 'MIT',
                link: 'git+https://github.com/facebook/react.git',
                remoteVersion: '18.2.0',
                installedVersion: '18.2.0',
                definedVersion: '^18.2.0',
                author: 'n/a'
            },
            {
                department: 'kessler',
                relatedTo: 'stuff',
                name: 'react-beautiful-dnd',
                licensePeriod: 'perpetual',
                material: 'material',
                licenseType: 'Apache-2.0',
                link: 'git+https://github.com/atlassian/react-beautiful-dnd.git',
                remoteVersion: '13.1.1',
                installedVersion: '13.1.1',
                definedVersion: '^13.1.1',
                author: 'Alex Reardon <areardon@atlassian.com>'
            },
            {
                department: 'kessler',
                relatedTo: 'stuff',
                name: 'react-chartjs-2',
                licensePeriod: 'perpetual',
                material: 'material',
                licenseType: 'MIT',
                link: 'git+https://github.com/reactchartjs/react-chartjs-2.git',
                remoteVersion: '5.2.0',
                installedVersion: '5.2.0',
                definedVersion: '^5.2.0',
                author: 'Jeremy Ayerst'
            },
            {
                department: 'kessler',
                relatedTo: 'stuff',
                name: 'react-dnd',
                licensePeriod: 'perpetual',
                material: 'material',
                licenseType: 'MIT',
                link: 'https://github.com/react-dnd/react-dnd.git',
                remoteVersion: '16.0.1',
                installedVersion: '16.0.1',
                definedVersion: '^16.0.1',
                author: 'n/a'
            },
            {
                department: 'kessler',
                relatedTo: 'stuff',
                name: 'react-dnd-html5-backend',
                licensePeriod: 'perpetual',
                material: 'material',
                licenseType: 'MIT',
                link: 'https://github.com/react-dnd/react-dnd.git',
                remoteVersion: '16.0.1',
                installedVersion: '16.0.1',
                definedVersion: '^16.0.1',
                author: 'n/a'
            },
            {
                department: 'kessler',
                relatedTo: 'stuff',
                name: 'react-dom',
                licensePeriod: 'perpetual',
                material: 'material',
                licenseType: 'MIT',
                link: 'git+https://github.com/facebook/react.git',
                remoteVersion: '18.2.0',
                installedVersion: '18.2.0',
                definedVersion: '^18.2.0',
                author: 'n/a'
            },
            {
                department: 'kessler',
                relatedTo: 'stuff',
                name: 'react-easy-crop',
                licensePeriod: 'perpetual',
                material: 'material',
                licenseType: 'MIT',
                link: 'git+https://github.com/ValentinH/react-easy-crop.git',
                remoteVersion: '4.7.5',
                installedVersion: '4.7.5',
                definedVersion: '^4.7.5',
                author: 'Valentin Hervieu <valentin@hervi.eu>'
            },
            {
                department: 'kessler',
                relatedTo: 'stuff',
                name: 'react-hook-form',
                licensePeriod: 'perpetual',
                material: 'material',
                licenseType: 'MIT',
                link: 'git+https://github.com/react-hook-form/react-hook-form.git',
                remoteVersion: '7.48.2',
                installedVersion: '7.43.5',
                definedVersion: '^7.43.5',
                author: '<bluebill1049@hotmail.com>'
            },
            {
                department: 'kessler',
                relatedTo: 'stuff',
                name: 'react-hotkeys-hook',
                licensePeriod: 'perpetual',
                material: 'material',
                licenseType: 'MIT',
                link: 'git+https://JohannesKlauss@github.com/JohannesKlauss/react-keymap-hook.git',
                remoteVersion: '4.4.1',
                installedVersion: '4.4.0',
                definedVersion: '^4.4.0',
                author: 'Johannes Klauss'
            },
            {
                department: 'kessler',
                relatedTo: 'stuff',
                name: 'react-multi-email',
                licensePeriod: 'perpetual',
                material: 'material',
                licenseType: 'MIT',
                link: 'git+https://github.com/axisj/react-multi-email.git',
                remoteVersion: '1.0.6',
                installedVersion: '1.0.6',
                definedVersion: '1.0.6',
                author: 'n/a'
            },
            {
                department: 'kessler',
                relatedTo: 'stuff',
                name: 'react-router-dom',
                licensePeriod: 'perpetual',
                material: 'material',
                licenseType: 'MIT',
                link: 'git+https://github.com/ReactTraining/react-router.git',
                remoteVersion: '4.3.1',
                installedVersion: '4.3.1',
                definedVersion: '^4.3.1',
                author: 'n/a'
            },
            {
                department: 'kessler',
                relatedTo: 'stuff',
                name: 'xlsx',
                licensePeriod: 'perpetual',
                material: 'material',
                licenseType: 'Apache-2.0',
                link: 'n/a',
                remoteVersion: 'n/a',
                installedVersion: '0.20.0',
                definedVersion: 'n/a',
                author: 'sheetjs'
            },
            {
                department: 'kessler',
                relatedTo: 'stuff',
                name: 'yup',
                licensePeriod: 'perpetual',
                material: 'material',
                licenseType: 'MIT',
                link: 'git+https://github.com/jquense/yup.git',
                remoteVersion: '1.3.2',
                installedVersion: '1.0.0',
                definedVersion: '^1.0.0',
                author: '@monasticpanic Jason Quense'
            },
            {
                department: 'kessler',
                relatedTo: 'stuff',
                name: 'zustand',
                licensePeriod: 'perpetual',
                material: 'material',
                licenseType: 'MIT',
                link: 'git+https://github.com/pmndrs/zustand.git',
                remoteVersion: '4.4.6',
                installedVersion: '4.3.8',
                definedVersion: '^4.3.8',
                author: 'Paul Henschel'
            }
        ]

        return (
            <body className='disclosure'>
            <Helmet>
                <title>Open source software disclosure for Adaje Services</title>
                <meta
                    name='description'
                    content='The Adaje Services include third-party code licensed to Adaje for use and redistribution under open-source licenses'
                />
                <meta property='og:title' content='Adaje – Disclosure' />
                <meta
                    property='og:description'
                    content='The Adaje Services include third-party code licensed to Adaje for use and redistribution under open-source licenses'
                />
                <meta name='twitter:card' content='summary_large_image' />
                <meta
                    name='twitter:description'
                    content='The Adaje Services include third-party code licensed to Adaje for use and redistribution under open-source licenses'
                />
                <meta
                    name='twitter:title'
                    content='Adaje – Disclosure'
                />
            </Helmet>
            <a
                href='#skipnavigation'
                className='skip-navigation'
                id='backtotop'>
                Skip Navigation
            </a>
            <div className='site-wrapper'>
                {this.props.mainNav}
                {this.props.mobileNav}
                <main className='global-main' id='skipnavigation'>
                    <section className='page-hero hero-animation'>
                        <div className='container'>
                            <h1>Open Source Software Disclosure</h1>
                        </div>
                    </section>
                    <section className='page-content pb-4'>
                        <div className='container'>
                            <div className='row center-md'>
                                <div className='col-lg-8 col-md-10'>
                                    <div className='intro-content'>
                                        <div className='content-styled'>
                                            <p>
                                                The Adaje Services include third-party code licensed to Adaje for use
                                                and redistribution under open-source licenses. Below is a list of
                                                disclosures and disclaimers in connection with such open-source licensed
                                                software that has been incorporated into the Adaje Services.
                                                Notwithstanding any of the terms and conditions of your subscription or
                                                license agreement with Adaje, the terms of certain open-source licenses
                                                may be applicable to your use of the Adaje Services, as set forth below.
                                                <br />
                                                <br />
                                                This list of open-source code (the “List”) was generated out-of-the-box
                                                by Adaje using third-party software licensed by Adaje that identifies
                                                open source code within the Services as of a particular date, and is
                                                intended to be a disclosure of a particular point in time only.
                                                Accordingly, you are advised that the List may be updated from time to
                                                time and may not be complete. Assuming you do not modify the open source
                                                code used within the Adaje Services, the use of the Adaje Services will
                                                not require you to grant to any party any of your intellectual property
                                                rights pursuant to an open source license, or require you to make any of
                                                your source code available to third parties pursuant to an open source
                                                software license.
                                                <br />
                                                <br />
                                                ADAJE MAKES NO REPRESENTATION OR WARRANTY, EXPRESS OR IMPLIED, WITH
                                                REGARD TO THE LIST OR ITS ACCURACY OR COMPLETENESS, OR WITH RESPECT TO
                                                ANY OBLIGATIONS ARISING AS A RESULT OF YOUR MODIFICATION TO SUCH OPEN
                                                SOURCE CODE OR TO THE SERVICES. BY USING THE ADAJE SERVICES, YOU AGREE
                                                THAT IN NO EVENT SHALL ADAJE BE LIABLE FOR ANY DAMAGES WHATSOEVER
                                                RESULTING FROM ANY SUCH MODIFICATIONS MADE BY YOU, OR ANY OBLIGATIONS
                                                ARISING THEREFROM, INCLUDING, WITHOUT LIMITATION, ANY SPECIAL,
                                                CONSEQUENTIAL, INCIDENTAL OR OTHER DIRECT OR INDIRECT DAMAGES.
                                            </p>
                                            <div className='overflow-auto'>
                                                <table className='w-full table-auto overflow-x-auto text-dimWhite'>
                                                    <thead>
                                                    <tr className='text-left text-white'>
                                                        <th>Name</th>
                                                        <th>Version</th>
                                                        <th>LicenseType</th>
                                                        <th>Link</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {packages.map(item => (
                                                        <tr key={item.name}>
                                                            <td>{item.name}</td>
                                                            <td>{item.installedVersion}</td>
                                                            <td>{item.licenseType}</td>
                                                            <td>
                                                                <a href={item.link.replace('git+', '')}>
                                                                    {item.link.replace('git+', '')}
                                                                </a>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
                {this.props.footer}
            </div>
            </body>
        );
    }
}

export default Disclosure;