import React from 'react';
import { UTIL } from './assets/js/main';
import { Helmet } from 'react-helmet';

class Terms extends React.Component {
    componentDidMount() {
        UTIL.loadEvents();
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <body className='terms'>
            <Helmet>
                <title>Adaje Privacy Policy</title>
                <meta
                    name='description'
                    content='Adaje Terms of Use'
                />
                <meta property='og:title' content='Adaje – Disclosure' />
                <meta
                    property='og:description'
                    content='Adaje Terms of Use'
                />
                <meta name='twitter:card' content='summary_large_image' />
                <meta
                    name='twitter:description'
                    content='Adaje Terms of Use'
                />
                <meta
                    name='twitter:title'
                    content='Adaje – Terms of Use'
                />
            </Helmet>
            <a
                href='#skipnavigation'
                className='skip-navigation'
                id='backtotop'>
                Skip Navigation
            </a>
            <div className='site-wrapper'>
                {this.props.mainNav}
                {this.props.mobileNav}
                <main className='global-main' id='skipnavigation'>
                    <section className='page-hero hero-animation'>
                        <div className='container pb-4'>
                            <h1>Adaje Terms of Use</h1>
                        </div>
                    </section>
                    <section className='page-content pb-4'>
                        <div className='container'>
                            <div className='row center-md'>
                                <div className='col-lg-8 col-md-10'>
                                    <div className='intro-content'>
                                        <div className='content-styled'>
                                            <h2>
                                                1. Use of Service
                                            </h2>
                                            <p>
                                                You will use the Service solely for your own purposes. You will not
                                                demonstrate, make
                                                available or otherwise describe the feature set of the Service to any
                                                person or entity (or representative thereof) that provides products or
                                                services that are similar to or in competition with those of Adaje.
                                                <br />
                                                <br />
                                                You will use the Service at all times in compliance with (i) these Terms
                                                and any policies, requirements, recommendations or protocols issued by
                                                Adaje to you, (ii) all applicable local, state, federal and
                                                international laws, regulations, and conventions and (iii) not in aid of
                                                any unlawful, inappropriate or improper act or in any manner that, in
                                                Adaje’s reasonable discretion, could adversely affect Adaje’s public
                                                image, goodwill, reputation or contractual relations, or could subject
                                                it to liability, or could threaten or put at risk Adaje’s or its
                                                third-party provider’s technology or facilities.
                                                <br />
                                                <br />
                                                You will not (i) license, sublicense, sell, resell, transfer, assign,
                                                distribute or otherwise make available to any third party the Service,
                                                Adaje technology or any component thereof, (ii) copy, modify, adapt,
                                                decompile, reverse engineer, generate source code, attempt to duplicate
                                                or make derivative works based upon Adaje technology, (iii) commercially
                                                exploit Adaje technology in any way, (iv) alter, remove or obscure (and
                                                will prevent third parties from altering, removing or obscuring) any
                                                trademark, copyright or other proprietary or restricted rights notices,
                                                or any associated disclaimers, that may appear in or through use of the
                                                service or Adaje technology, or (vi) use any services or Adaje
                                                technology for any purpose other than that for which it is intended.
                                            </p>
                                            <h2>
                                                Password and Account Access
                                            </h2>
                                            <p>
                                                You are entirely responsible for maintaining the confidentiality of the information you hold for your account, including your password, and for any and all activity that occurs under your account as a result of your failing to keep this information secure and confidential. You agree to notify Adaje immediately of any unauthorized use of your account or password, or any other breach of security. Adaje cannot and will not be liable for any loss or damage arising from your failure to comply with these obligations.
                                            </p>
                                            <h2>
                                                Protection of Your Information
                                            </h2>
                                            <p>All data and information input by you to the Service or generated by you using the Service shall at all times, as between you and Adaje, remain your exclusive property. Adaje will use systems infrastructure for the Service that is consistent with commercially reasonable practices of similar providers of similar services designed to protect your data against unauthorized access, misappropriation or loss and to keep your data available to you. Notwithstanding anything to the contrary, under no circumstances will Adaje use your data for any purpose, other than (i) to provide the Service to you, (ii) to monitor, maintain, develop, update, enhance or improve Adaje’s software, systems or services, and (iii) offer additional services that Adaje believes may be useful to you.</p>
                                            <h2>Proprietary Rights</h2>
                                            <p>Use, provision or receipt of Service, do not convey any rights of ownership to you in or related to any of the Service, Adaje technology (including software, code, equipment, systems, products, processes, user interfaces, know-how, documentation, techniques, designs or other tangible or intangible technical material or information) or Adaje intellectual property rights, all of which remain with Adaje. The Adaje name, logo, and the product names associated with the Service are trademarks of Adaje, and no right or license is granted to you to use them. The Service and all intellectual property rights of Adaje, including any patents, copyrights, trademarks and trade secrets, are and remain the valuable property of Adaje. Licensed software and products included in the Service, and all associated intellectual property rights of third parties, are and remain the property of their respective owner.
                                            </p>
                                            <h2>Disclaimers</h2>
                                            <p>ADAJE DOES NOT PROMISE THAT THE SERVICE WILL BE ERROR-FREE OR UNINTERRUPTED, OR THAT ANY DEFECTS WILL BE CORRECTED, OR THAT YOUR USE OF THE SERVICE WILL PROVIDE SPECIFIC RESULTS. THE SERVICE AND ITS CONTENT ARE DELIVERED ON AN "AS-IS" AND "AS-AVAILABLE" BASIS. ALL INFORMATION PROVIDED THROUGH THE SERVICE IS SUBJECT TO CHANGE WITHOUT NOTICE. ADAJE DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING ANY WARRANTIES OF ACCURACY, NON-INFRINGEMENT, MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. ADAJE DISCLAIMS ANY AND ALL LIABILITY FOR THE ACTS, OMISSIONS AND CONDUCT OF ANY THIRD PARTIES IN CONNECTION WITH OR RELATED TO YOUR USE OF THE SERVICE. YOU ASSUME TOTAL RESPONSIBILITY FOR YOUR USE OF THE SERVICE. YOUR SOLE REMEDY AGAINST ADAJE FOR DISSATISFACTION WITH THE SERVICE IS TO STOP USING THE SERVICE. THIS LIMITATION OF RELIEF IS A PART OF THE BARGAIN BETWEEN THE PARTIES.
                                            </p>
                                            <h2>Limitations of Liability</h2>
                                            <p>If you have any basis for recovering Damages (including breach of these
                                                Terms), you agree that your exclusive remedy is to recover, from Adaje
                                                or any affiliates, resellers, and distributors, Damages up to an amount
                                                equal to any Service fee for the month during which the loss or breach
                                                occurred (or up to $10.00 if the Service is free). You cannot recover
                                                any other damages or losses, including direct, consequential, lost
                                                profits, special, indirect, incidental, or punitive. These limitations
                                                and exclusions apply even if this remedy does not fully compensate you
                                                for any losses or fails of its essential purpose or if Adaje knew or
                                                should have known about the possibility of the damages. To the maximum
                                                extent permitted by law, these limitations and exclusions apply to
                                                anything or any claims related to these Terms, the Service, or the
                                                software related to the Service.
                                                <br />
                                                <br />
                                                “Damages” means any and all losses, claims, obligations, liabilities,
                                                actions, suits, proceedings, demands, judgments, payments, costs and
                                                expenses (including court costs, amounts paid in settlement, judgments,
                                                and reasonable attorney fees and other expenses) and damages of any
                                                kind, nature or description whatsoever.
                                            </p>
                                            <h2>Indemnification</h2>
                                            <p>You agree to indemnify and hold harmless Adaje and its officers, directors, shareholders, successors in interest, employees, agents, subsidiaries and affiliates from, against and in respect of any and all Damages made against Adaje by any third party due to or arising out of or in connection with your use of the Service.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
                {this.props.footer}
            </div>
            </body>
        );
    }
}

export default Terms;