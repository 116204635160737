function isStaging() {
    return window.location.hostname === 'home.staging.adaje.net';
}

function isDev() {
    return window.location.hostname === 'home.adaje.net';
}

function isProd() {
    return window.location.hostname === 'adajeinc.com';
}

const setHost = () => {
    if (isProd()) return 'https://api.adajeinc.com';
    if (isDev()) return 'https://api.adaje.net'
    if (isStaging()) return 'https://api.staging.adaje.net'
    return 'http://localhost:3001/api'
};

const setLoginRedirectUrl = () => {
    if (isProd()) return 'https://app.adajeinc.com';
    if (isDev()) return 'https://app.adaje.net'
    if (isStaging()) return 'https://app.staging.adaje.net'
    return 'http://localhost:3001/api'
};

export const constants = {
    host: setHost(),
    loginRedirectUrl: setLoginRedirectUrl(),
    urlPrefix: '/v006',
    tokenRefreshTimeout: 1000 * 60 * 30, //Thirty minutes, token expires in 1hr
    logoutDueToInactiveTimeout: 1000 * 60 * 60 * 24 //A day
};
