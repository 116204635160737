import React from 'react';
import { UTIL } from './assets/js/main';
import { Helmet } from 'react-helmet';

class Privacy extends React.Component {
    componentDidMount() {
        UTIL.loadEvents();
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <body className='privacy'>
            <Helmet>
                <title>Adaje Privacy Policy</title>
                <meta
                    name='description'
                    content='This Privacy Notice describes Adaje’s policies and practices regarding its collection and use of customer data, and sets forth your privacy rights.'
                />
                <meta property='og:title' content='Adaje – Disclosure' />
                <meta
                    property='og:description'
                    content='This Privacy Notice describes Adaje’s policies and practices regarding its collection and use of customer data, and sets forth your privacy rights.'
                />
                <meta name='twitter:card' content='summary_large_image' />
                <meta
                    name='twitter:description'
                    content='This Privacy Notice describes Adaje’s policies and practices regarding its collection and use of customer data, and sets forth your privacy rights.'
                />
                <meta
                    name='twitter:title'
                    content='Adaje – Privacy'
                />
            </Helmet>
            <a
                href='#skipnavigation'
                className='skip-navigation'
                id='backtotop'>
                Skip Navigation
            </a>
            <div className='site-wrapper'>
                {this.props.mainNav}
                {this.props.mobileNav}
                <main className='global-main' id='skipnavigation'>
                    <section className='page-hero hero-animation'>
                        <div className='container pb-4'>
                            <h1>Adaje Privacy Policy</h1>
                        </div>
                    </section>
                    <section className='page-content pb-4'>
                        <div className='container'>
                            <div className='row center-md'>
                                <div className='col-lg-8 col-md-10'>
                                    <div className='intro-content'>
                                        <div className='content-styled'>
                                            <h2>
                                                Introduction
                                            </h2>
                                            <p>
                                                Adaje offers an enterprise software platform as a service (the “Service”). This Privacy Notice describes Adaje’s policies and practices regarding its collection and use of customer data, and sets forth your privacy rights. We recognize that information privacy is an ongoing responsibility, and so we will from time to time update this Privacy Notice as we undertake new data practices or adopt new privacy policies.
                                            </p>
                                            <h2>
                                                Use of the Service
                                            </h2>
                                            <p>
                                                As is true of most other websites, Adaje’s website collects certain
                                                information automatically and stores it in log files. The information
                                                may include internet protocol (IP) addresses, the region or general
                                                location where your computer or device is accessing the internet,
                                                browser type, operating system and other usage information about the use
                                                of Adaje’s website, including a history of the pages you view. We use
                                                this information to help us design our site to better suit our users’
                                                needs. We may also use your IP address to help diagnose problems with
                                                our server and to administer our website, analyze trends, track visitor
                                                movements, and gather broad demographic information that assists us in
                                                identifying visitor preferences.
                                                <br />
                                                <br />
                                                Adaje has a legitimate interest in understanding how members, customers
                                                and potential customers use its website. This assists Adaje with
                                                providing more relevant products and services, with communicating value
                                                to our sponsors and corporate members, and with providing appropriate
                                                staffing to meet member and customer needs.
                                            </p>
                                            <h2>
                                                Customer Data
                                            </h2>
                                            <p>All data and information input by Customer to the Service or generated by Customer using the Service (collectively, “Customer Data”) shall at all times, as between Customer and Adaje, remain Customer’s exclusive property.</p>
                                            <h2>Sharing information with third parties</h2>
                                            <p>Customer Data is stored in one or more databases hosted by third parties
                                                located in the United States. These third parties do not use or have
                                                access to Customer Data for any purpose other than cloud storage and
                                                retrieval.
                                                <br />
                                                <br />
                                                We do not otherwise reveal Customer Data to non-Adaje persons or
                                                businesses for their independent use unless: (1) you request or
                                                authorize it; (2) the information is provided to comply with the law
                                                (for example, compelled by law enforcement to comply with a search
                                                warrant, subpoena, or court order), enforce an agreement we have with
                                                you, or to protect our rights, property or safety, or the rights,
                                                property or safety of our employees or others; (3) the information is
                                                provided to our agents, vendors or service providers who perform
                                                functions on our behalf; (4) to address emergencies or acts of God; or
                                                (5) to address disputes, claims, or to persons demonstrating legal
                                                authority to act on your behalf. We may also gather aggregated data
                                                about our services and website visitors and disclose the results of such
                                                aggregated (but not personally identifiable) information to our
                                                partners, service providers, advertisers, and/or other third parties for
                                                marketing or promotional purposes.
                                                <br />
                                                <br />
                                                The Adaje website connects with third party services such as Facebook,
                                                LinkedIn, Twitter and others. If you choose to share information from
                                                the Adaje website through these services, you should review the privacy
                                                policy of that service. If you are a member of a third party service,
                                                the aforementioned connections may allow that service to connect your
                                                visit to our site to your personal data.
                                            </p>
                                            <h2>Data storage and retention</h2>
                                            <p>Adaje will use systems infrastructure for the Service that is consistent
                                                with commercially reasonable practices of similar providers of similar
                                                services designed to protect Customer Data against unauthorized access,
                                                misappropriation or loss and to keep Customer Data available to
                                                Customer.
                                                <br />
                                                <br />
                                                At the end of the Term: (a) Adaje will provide Customer with a
                                                reasonable opportunity to extract Customer Data in a manner that enables
                                                Customer to continue using Customer Data and minimizes disruption to
                                                Customer’s business; and (b) after an agreed upon period, Adaje shall
                                                delete (which, for purposes hereof, means to render irretrievable or
                                                identifiable) all Customer Data from Adaje’s computers, systems and data
                                                storage devices and data repositories (including those based in the
                                                cloud).
                                                <br />
                                                <br />
                                                Notwithstanding anything to the contrary, under no circumstances will
                                                Adaje use Customer Data for any purpose, other than (i) to provide
                                                Service to Customer in accordance with this Agreement or (ii) to
                                                monitor, maintain, develop, update, enhance or improve Adaje’s software,
                                                systems or services, provided that any such use of Customer Data
                                                external to Adaje shall be aggregated and anonymized to prevent any
                                                Customer Data from being identified with Customer or any of its clients.
                                            </p>
                                            <h2>Questions, concerns or complaints</h2>
                                            <p>If you have questions, concerns, complaints, or would like to exercise your rights, please contact your Adaje representative.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
                {this.props.footer}
            </div>
            </body>
        );
    }
}

export default Privacy;
